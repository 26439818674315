@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'SakeozosAslFont';
  src: local('SakeozosAslFont'), url(./fonts/SakeozosAslFont-Regular.ttf) format('truetype');
}

@import url('https://fonts.googleapis.com/css2?family=Righteous&display=swap');

*,
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  /* background-color: hsl(231, 16%, 92%); */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
#root,
body,
html {
  /* Prevent overscroll on chrome (not supported on safari) */
  overscroll-behavior-y: none;
}
.h-fill {
  height: 100vh;
  max-height: -webkit-fill-available;
}
